import * as SplashScreen from 'expo-splash-screen'
import { Text, View, Pressable } from 'react-native'
import * as Linking from 'expo-linking'

void SplashScreen.preventAutoHideAsync()

const App = () => {
  return (
    <View
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1,
        width: '100%',
        paddingHorizontal: 10
      }}
    >
      <Text style={{ textAlign: 'center', fontSize: 24, fontWeight: 'bold' }}>
        The development phase for testing purposes has ended.
      </Text>
      <Text style={{ textAlign: 'center', fontSize: 20, marginTop: 30 }}>
        Please go to the production app by clicking here:
      </Text>
      <Pressable
        onPress={async () =>
          await Linking.openURL('https://app.apmlaboravailability.com')
        }
      >
        <Text
          style={{
            textAlign: 'center',
            fontSize: 20,
            marginTop: 20,
            color: '#FF5939',
            textDecorationLine: 'underline'
          }}
        >
          app.apmlaboravailability.com
        </Text>
      </Pressable>
    </View>
  )
}

export default App
